import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';

export default function IndexBanner({ children }) {
  return (
    <Paper
      className='animated-gradient-bg'
      style={{
        position: 'relative',
      }}
      sx={{ height: { md: '650px', xs: '550px' } }}>
      <Stack
        container
        justifyContent="center"
        spacing={8}
        sx={{ padding: 2 }}
        style={{
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          zIndex: 1
        }}>
        {children}
      </Stack>
    </Paper >
  );
}