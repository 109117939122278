import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { URL, ENUM_USERTYPE } from "../src/config"
import IndexBanner from '../components/Layout/IndexBanner';
import Layout from '../components/Layout/Layout'
import AppContext from 'AppContext';
import SearchJobsPosts from '../components/Job/SearchJobPosts';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

export default function Index(props) {
  const { user, setUser } = React.useContext(AppContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Layout pageTitle={URL.TITLE.INDEX} pageDesc={"Search, post and apply for dentist jobs in India."} noContentPadding>
      <IndexBanner>
        <Box>
          <img src={"/img/logo.png"} alt="LOGO" width='300px' />
        </Box>

        {
          user ?
            <>
              <Box>
                <Typography color="common.white" variant="h4" >
                  Find and post dental jobs in India
                </Typography>

                {
                  user.type == ENUM_USERTYPE.EMPLOYER &&
                  <Typography color="common.white" variant="subtitle1" sx={{ mt: 1 }}>
                    Hire the nation's best Dental Professionals
                  </Typography>
                }

                {
                  user.type == ENUM_USERTYPE.PROFESSIONAL &&
                  <Typography color="common.white" variant="subtitle1" sx={{ mt: 1 }}>
                    Find the right job for your skills
                  </Typography>
                }
              </Box>

              <Box>
                {
                  user.type == ENUM_USERTYPE.EMPLOYER &&
                  <Button
                    href={URL.WEB.POST_JOB}
                    variant="contained" color="primary" size="large" sx={{ textTransform: "none", width: 'fit-content', px: 10 }}>
                    Post a Job
                  </Button>
                }
                {
                  user.type == ENUM_USERTYPE.PROFESSIONAL &&
                  <Button
                    href={URL.WEB.SEARCH_JOB_POSTS}
                    variant="contained" color="primary" size="large" sx={{ textTransform: "none", width: 'fit-content', px: 10 }}>
                    Search Jobs
                  </Button>
                }
              </Box>
            </>
            :
            <>
              <Box>
                <Typography color="common.white" variant="h4" >
                  Find and post dental jobs in India
                </Typography>

                <Typography color="common.white" variant="subtitle1" sx={{ mt: 1 }}>
                  Join the largest job portal for dental professionals and dental clinics in India
                </Typography>
              </Box>

              <Box>
                <Button
                  href={URL.WEB.SIGNUP}
                  variant="contained" color="secondary" size="large" sx={{ textTransform: "none", width: 'fit-content', px: 10 }}>
                  Get Started
                </Button>
              </Box>
            </>
        }
      </IndexBanner>

      {user &&
        <Box sx={{ mx: "auto", width: { md: "75%" } }}>
          <Box sx={{ bgcolor: "job.bg", my: 4, mx: 2, py: 5, px: 1 }}>
            <Typography color="common.black" variant="h5" sx={{ mx: "auto", width: 'fit-content', mb: 2 }} >
              Search dental jobs in India
            </Typography>

            <SearchJobsPosts page_size={2} small />
          </Box>
        </Box>
      }

      {!user &&
        <>

          <Stack direction="row" spacing={2} m={2} alignItems="center">

            <Stack
              textAlign="left"
              direction="column"
              spacing={4}
              p={4}
            >
              <Box
                p={2}
                my={2}
                color="textBlue.main"
              >
                <Typography variant="h3" gutterBottom>
                  Welcome to {URL.TITLE.INDEX}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Connecting Indian dental professionals and dental clinics for a better job market.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Looking for a job in the dental field? Look no further than {URL.TITLE.INDEX}! We are dedicated to
                  connecting dental professionals with the best job opportunities available across the country.
                </Typography>
              </Box>

              <Box
                p={2}
                my={2}
                boxShadow={4}
              >
                <Typography variant="h5" gutterBottom>
                  Search Dental Jobs
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We offer a comprehensive search tool that allows you to look for jobs in your region or across India. You can filter your search by location, job type, and salary; making it easy to find the perfect job for you.
                </Typography>
              </Box>

              <Box
                p={2}
                my={2}
                boxShadow={4}
              >
                <Typography variant="h5" gutterBottom>
                  Dental Clinics, Post Dental Jobs
                </Typography>
                <Typography variant="body1" gutterBottom>
                  As an employer, you can post job listings with us to reach out to qualified candidates. Our portal is designed to connect employers with the best talent available, making it easy to find the perfect candidate.
                </Typography>
              </Box>

              <Box
                p={2}
                my={2}
                boxShadow={4}
              >
                <Typography variant="h5" gutterBottom>
                  Upload Your Resume
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Upload your resume here and let employers find you. Our portal makes it easy for employers to search for candidates with the skills and experience they're looking for.
                </Typography>
              </Box>
              <Box
                p={2}
                my={2}
                boxShadow={4}
              >
                <Typography variant="h5" gutterBottom>
                  Get Expert Advice
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We also offer expert advice and career resources to help you improve your job search and advance your career. Join us to learn more about the dental industry.
                </Typography>
              </Box>

              {/*  */}

              <Box
                p={2}
                my={2}
                boxShadow={4}
              >
                <Typography variant="h5" gutterBottom>
                  Are you a dental professional?
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {URL.TITLE.INDEX} allows you to build your profile, upload your resume, view job posts, and apply to your dream dental job; all in one place.
                </Typography>
              </Box>

              <Box
                p={2}
                my={2}
                boxShadow={4}
              >
                <Typography variant="h5" gutterBottom>
                  Are you a dental clinic?
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {URL.TITLE.INDEX} is the perfect spot to recruit the right dental professionals; you can post your dental job openings to get the perfectly matched applicants with their full information.
                </Typography>
              </Box>

              {/*  */}

              <Stack
                p={2}
                my={2}
                boxShadow={4}
                spacing={2}
                color="textBlue.main"
              >
                <Typography variant="h5" gutterBottom>
                  Sign up for free to gain access to the best Indian online dental job board, where you can post and browse jobs!
                </Typography>


                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      href={URL.WEB.SIGNUP}
                      variant="contained"
                      color="secondary"
                      size="large"
                      sx={{ textTransform: "none", width: 'fit-content', px: 10 }}
                    >
                      Get Started
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>

            {
              !isMobile &&
              <Box
                p={4}>
                {/* <img src={"/img/indian_dentist.jpg"} width='400px' alt="sign up" style={{ borderRadius: '5px' }} /> */}

                {/* <video className='intro-side-video' autoPlay muted loop playsInline>
                  <source src={"/img/intro_video.mp4"} type="video/mp4" />
                  Your browser does not support the video tag.
                </video> */}
              </Box>
            }

          </Stack>
        </>
      }

    </Layout>
  );
}